export const typeSchema = [
  {
    group: [
      {
        class: "row",
        group: [
          {
            // class: "row",
            group: [
              {
                class: "row",
                group: [
                  {
                    class: "col-12 d-flex justify-content-between align-items-center mb-4",
                    component: [
                      // User type information
                      {
                        name: "9",
                        label: "201",
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                        request: ' *'
                      },
                    ],
                  },
                ],
              },

              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // User Type Name TH
                      {
                        label: "100",
                        type: "InputText",
                        datavalue: "strUserTypeNameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpUserTypeNameTH",
                        validate: "letterTH",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // User type Label
                      {
                        label: "99",
                        type: "InputText",
                        datavalue: "strUserTypeNameEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpUserTypeNameEN",
                        validate: "letterEN",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",
                group: [{
                  class: "col-12",
                  component: [{
                    strName: "448",
                    star: "*",
                    type: "Selectdropdown",
                    List: true,
                    state: null,
                    blnTooltip: true,
                    tooltipMsg: "1273",
                    datavalue: "intUsergroupID",
                    blnSystemword: true,
                    blnAddress: false,
                    DropdownItem: [
                      { id: 2, lang: "451" },
                      { id: 1, lang: "450" },
                      { id: 3, lang: "452" },
                    ],

                    alertMsg: "284",
                    //sub: "1",
                    // altMsgBox: "421",
                  }]
                }]
              },

              {
                class: "row",

                group: [
                  {
                    class:
                      "modal-footer border-0 justify-content-start p-0 mt-6 ",
                    component: [
                      {
                        type: "Button",
                        name: "36", // OK
                        value: false,
                        state: "submit",
                        class: "btn-fix me-3",
                      },
                      {
                        type: "Button",
                        name: "35", // Cancel
                        value: false,
                        state: "cancel",
                        class: "btn-fix btn-cancel",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const request = ["strUserTypeNameTH", "strUserTypeNameEN", "intUsergroupID"];
