<template>
  <!-- <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess || blnAltLoadingData"
    :class="
      blnAltStatus || blnAltStatusunsucess || blnAltLoadingData ? '' : 'd-none'
    "
  > -->
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <!-- </div> -->
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('98')"
        :Helper="$t('345')"
        @confirm="fetchDeleteUsertype($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage()"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
      ></div>
    </transition>
  </div>
  <!-- confirm Insert -->
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmInsert == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmInsert == true"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('453')"
        :Helper="$t('345')"
        :strButtonSubmit="$t('283')"
        @confirm="ConfirmInsert($event)"
        ref="AlertPopupp"
        :btnpop="false"
        classIcon="fill-warning"
        :Cancel="true"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmInsert == true"
        @click="blnPopupConfirmInsert = !blnPopupConfirmInsert"
      ></div>
    </transition>
  </div>
  <Header
    icon="usertypeOutline"
    :headerName="$t('9')"
    :btnName="$t('97')"
    :breadcrumbname="
      action == 'Insert' ? $t('97') : '' || action == 'Update' ? $t('394') : ''
    "
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true ? $t('200', { fieldname: $t('9').toLowerCase() }) : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListCampusClass="d-none"
    :Searchname="$t('96')"
    @onSearch="SearchUsertype($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: true,
    }"
    @ConfigDisplay="reloadConfigDisplay()"
    :tooltipPlacement="
      device == 'mobile' ? 'tooltip-top-mobile' : 'tooltip-top'
    "
    ref="header"
  />
  <div v-show="blnOpenFilter == true">
    <Filter
      @appectFilter="filteredData($event)"
      :listDataFilter="listUsertypeFilter"
      @Cancel="blnOpenFilter = !blnOpenFilter"
      :listActiveFiltersSelected="activeFiltersSelected"
      :blnHaveDecision="false"
      ref="filter"
    />
  </div>

  <Table
    :progressScore="progress"
    :nametable="'9'"
    v-show="ListUserType.dataFields != null"
    v-if="!blnOpenform"
    :ListDataTable="ListUserType"
    firstSort="-"
    secondSort="strUserTypeNameEN"
    @edit="fetchGetUserTypebyId($event)"
    @delete="fetchDeleteUsertype($event)"
    @ConfigDisplay="reloadConfigDisplay()"
    @onSaveConfigDisplay="onSaveConfigDisplay($event)"
    ref="table"
  />
  <!-- @ConfigDisplay="reloadConfigDisplay()" -->
  <div
    class="d-flex mt-sm-3 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'form-md mb-4'"
    >
      <Form
        v-if="blnOpenform == true"
        :typeSchema="typeSchema"
        :data="formdata"
        @onInput="openform($event)"
        @Insert="(blnPopupConfirmInsert = true), ConfirmInsert($event)"
        @Update="fetchUpdateUserType($event)"
        @delete="ConfirmDelete()"
        :action="action"
        :request="request"
        :inactive="formInactive"
        :msgalertDataDuplicate="msgalertDataDuplicate"
        ref="form"
      />
    </div>
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('9').toLowerCase() })"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>

<script>
import AlertPopup from "@/components/alert/alertPopup.vue";
import Relate from "@/components/cards/relate.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import urlapi from "@/typescript/urlapi/url";
import apiusertype from "@/typescript/urlapi/adminsystem/APIUsertype";
import axios from "axios";
//import SortArray from "../typescript/sort/sorting";
// import PositionField from "../components/table/position.vue";
import Enumerable from "linq";
import Form from "@/components/form/form.vue";
import { typeSchema, request } from "@/components/constants/schemaAddUserType";
import AltStatus from "@/components/alert/alertStatus.vue";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
export default {
  components: {
    Header,
    Table,
    Filter,
    Form,
    AltStatus,
    Relate,
    AlertPopup,
    // PositionField,
  },
  data() {
    return {
      blnloadError: false,
      blnloadConfirmDelet: false,
      strSearch: "",
      formInactive: [],
      listRelate: [],
      blnPopupConfirmDelete: false,
      blnPopupConfirmInsert: false,
      timerCountHover: 0,
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      hover: false, //hover alt
      blnAltLoadingData: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      typeSchema: typeSchema, //
      timerEnabled: false,
      request: request,
      usertypeId: null,
      timerCount: 0,
      progress: 0,
      formdataDummy: {},
      formdata: {},
      blnOpenform: false,
      activeFiltersSelected: [],
      ListUserType: {},
      ListDummy: {},
      blnOpenFilter: false,
      listUsertypeFilter: [],
      intWrongLogin: 0,
      intPinCount: 1,
      strTel: "",
      updateAdsInventories: null,
      insertdata: {},
      errorMsg: "",
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.updateAdsInventories = setInterval(() => {
      // console.log(AdsInventories.blnCloseform);
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 300);
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    // Popup delete
    ConfirmDelete() {
      // console.log("click button delete");
      this.blnError = false;
      this.$refs.AlertPopup.blnShowMSG = false;
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    // Popup Insert
    ConfirmInsert(event) {
      // console.log(event);
      if (event == true) {
        this.blnPopupConfirmInsert = false;
        this.fetchInsertUsertype(this.insertdata);
      } else if (event == false) {
        this.blnPopupConfirmInsert = !this.blnPopupConfirmInsert;
      } else {
        this.insertdata = {};
        this.insertdata = event;
      }
    },
    // skeleton loading ข้อมูล ข้อมูลผู้ใช้
    async processLoadingData() {
      this.listRelate = [];
      this.progress = 0;
      await this.fetchListUsertype().then(() => {
        this.fetchCheckDeleteUserType().then(() => {
          this.processData().then(() => {
            setTimeout(() => {
              // console.log("100");
              if (this.blnloadError == false) {
                this.progress = 100;
              }
            }, 1000);
            //this.progress = 100;
          });
        });
      });
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      try {
        setTimeout(() => {
          if (Array.isArray(this.ListUserType.data)) {
            this.ListUserType.data = Enumerable.from(this.ListUserType.data)
              .orderByDescending((r) =>
                r.strUserTypeNameEN.toString().toLowerCase()
              )
              .toArray();
            this.ListUserType.data = this.ListUserType.data.reverse();
          }
        }, 1000);
        this.progress += 10;
        if (
          localStorage.getItem("SelectedListUserType") == null ||
          localStorage.getItem("SelectedListUserType") == "undefined"
        ) {
          localStorage.setItem(
            "SelectedListUserType",
            JSON.stringify(this.ListUserType.defaultField)
          );
        }
        this.progress += 10;

        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
            this.$refs.table.calculatePage(
              this.ListUserType.total,
              this.ListUserType.data
            );
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem("SelectedListUserType") != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListUserType"))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              localStorage.setItem(
                "SelectedListUserType",
                JSON.stringify(this.ListUserType.defaultField)
              );
              this.$refs.table.setSelectedList(this.ListUserType.defaultField);
            }
          }

          this.progress += 10;
          this.timerEnabled = true;
        }, 1000);
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    // อัพเดทข้อมูล ข้อมูลผู้ใช้ด้วยไอดี
    async fetchUpdateUserType(data) {
      this.msgalertDataDuplicate = [];
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      let strUserTypeNameEN = data.strUserTypeNameEN;
      let strUserTypeNameTH = data.strUserTypeNameTH;
      const formdata = { strUserTypeNameEN, strUserTypeNameTH };
      let isEqual = JSON.stringify(this.formdataDummy) === JSON.stringify(data);
      if (isEqual == true) {
        await axios
          .get(urlapi + apiusertype.getUserTypeByID + this.usertypeId)
          .then((res) => {
            if (
              res.data.message ==
              "Not found ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              setTimeout(() => {
                this.blnnotedit = false;
                this.processLoadingData();
              }, 5000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
      if (isEqual == false) {
        // console.log(data.strUserTypeNameEN);
        await axios
          .put(
            urlapi +
              apiusertype.updateUserType +
              this.usertypeId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              "&Confirm=true",
            formdata
          )
          .then((res) => {
            // console.log(res);
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            }
            if (res.data.edited == false) {
              // console.log(res.data.edited);
              if (res.data.usertypenameEN == true) {
                let Message = { name: "strUserTypeNameEN", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              if (res.data.usertypenameTH == true) {
                let Message = { name: "strUserTypeNameTH", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                  this.processLoadingData();
                }, 5000);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
    },
    // ดึงข้อมูล ข้อมูลผู้ใช้ด้วยไอดี
    async fetchGetUserTypebyId(usertypeId) {
      this.formInactive = [];
      try {
        var resRelate = await axios.get(
          urlapi + apiusertype.checkusedstatus + usertypeId
        );
        this.listRelate = { ...resRelate.data };
        // if (this.listRelate.used == true) {
        //   var activeDelete = {
        //     name: "blnDeleteinForm",
        //     inactive: true,
        //     tooltip: false,
        //   };
        //   this.formInactive.push(activeDelete);
        //   console.log(this.formInactive);
        // } else {
        //   this.formInactive = {};
        // }
        // console.log(this.listRelate);
      } catch (error) {
        console.log(error);
        this.blnAltStatusunsucess = true;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
      }
      this.usertypeId = usertypeId;

      await axios
        .get(urlapi + apiusertype.getUserTypeByID + usertypeId)
        .then((res) => {
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            setTimeout(() => {
              this.blnnotedit = false;
              this.processLoadingData();
            }, 5000);
          } else {
            this.action = "Update";
            this.formdata = res.data.data;
            this.formdataDummy = { ...res.data.data };
            this.blnOpenform = true;
            if (this.action === "Update") {
              this.formInactive = [];
              let notEdit = {
                name: "intUsergroupID",
                inactive: true,
              };
              this.formInactive.push(notEdit);
            }
          }
        })
        .catch(() => {
          this.blnAltStatusunsucess = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        });
    },
    // เพิ่มข้อมูล ข้อมูลผู้ใช้
    async fetchInsertUsertype(data) {
      this.msgalertDataDuplicate = [];
      // console.log(data);
      // var stringToJsonObject = JSON.stringify(data);
      // console.log(stringToJsonObject);
      try {
        var res = await axios.post(
          urlapi +
            apiusertype.insertUserType +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            "&Confirm=true",
          data
        );
        // console.log(res);
        if (res.data.inserted == true) {
          this.blnOpenform = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.processLoadingData();
          }, 1000);
        }
        if (res.data.inserted == false) {
          // console.log(res.data.insert);
          if (res.data.usertypenameEN == true) {
            let Message = { name: "strUserTypeNameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.usertypenameTH == true) {
            let Message = { name: "strUserTypeNameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }
        if (res.data.code === 500) {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;

        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    //ตรวจสอบว่าสามารถลบได้หรือไหม
    async fetchCheckDeleteUserType() {
      // this.blnloadError = true;
      if (Array.isArray(this.ListUserType)) {
        this.ListUserType.data.forEach((element, index) => {
          try {
            var res = axios.get(
              urlapi + apiusertype.checkdDeleteUserType + element.strID
            );
            // console.log(res);
            // this.blnloadError = false;

            // ถ้าลบได้ เก็บค่าตัวแปร blnCampusStatus เป็น false
            if (res.data.candelete == true) {
              this.ListUserType.data[index]["candelete"] = true;
            }
            //ถ้าลบไม่ได้ เก็บค่าตัวแปร blnCampusStatus เป็น true
            else {
              this.ListUserType.data[index]["candelete"] = false;
            }
          } catch (error) {
            this.blnAltLoadingData = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;
            setTimeout(() => {
              this.blnAltLoadingData = false;
            }, 5000);
            throw new Error(error);
          }
        });
      }
    },
    // reloadpage ไปหน้า logout
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    //ลบข้อมูลด้วย ID
    async fetchDeleteUsertype(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          // console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.delete(
                urlapi + apiusertype.deleteUserType + this.usertypeId,
                {
                  data: login,
                },

                { headers: { "Content-Type": "application/json" } }
              );

              // console.log(res.data);
              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.blnAltStatus = true;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.progress = 0;

                //delay 1 sec
                setTimeout(() => {
                  if (this.$refs.header != null) {
                    this.$refs.header.onClear();
                  }
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
              } else if (res.data.candelete == false) {
                this.blnOpenform = false;
                //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                if (
                  res.data.message ==
                  "Cannot delete ,Please check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              } else {
                this.blnAltStatusunsucess = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;
                setTimeout(() => {
                  this.blnAltStatusunsucess = false;
                }, 5000);
              }
              this.blnPopupConfirmDelete = false;
              // reset PIN หลังจากDelete
              this.$refs.AlertPopup.password = null;
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
              // this.$refs.AlertPopup.password = null;
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        this.blnloadConfirmDelet = false;
        console.log(error);
      }
    },
    // ดึงข้อมูล ข้อมูลผู้ใช้มาแสดงใน table
    async fetchListUsertype() {
      this.blnloadError = true;
      await axios
        .get(urlapi + apiusertype.getUserTypeList)
        .then((res) => {
          this.progress += 10;
          this.ListDummy = res.data;
          // console.log(res.data);
          this.ListUserType = res.data;
          this.blnloadError = false;
        })
        .catch((e) => {
          // this.blnAltLoadingData = true;
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            // this.blnAltLoadingData = false;
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
      return this.ListUserType;
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListUserType.data = ListDummy.slice();
      for (var field in activeFilters) {
        this.ListUserType.data = this.ListUserType.data.filter(
          (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        );
      }
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListUserType.data.length,
          this.ListUserType.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;
      this.ListDummy.data = ListDummy;
    },
    // รับค่าจาก component filter
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;

      this.filter(activeFilters, this.ListDummy.data);
    },
    // เปิด Filter
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    // ค้นหาข้อมมูล ข้อมูลผู้ใช้
    SearchUsertype(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event);
    },
    // clear ข้อมูลค้นหา
    clean() {
      this.strSearch = "";
      this.SearchUsertype("");
      //this.filteredData(this.activeFiltersSelected);
    },
    // ส่งค่าปิดฟอร์ม
    closeForm(event) {
      this.$emit("onInput", event);
    },
    //เปิดฟอร์ม
    async openform(state) {
      // console.log(state);
      try {
        //ปิดฟอร์ม
        if (state == false) {
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          this.formdataDummy = {};
          this.ListDummy = {};
          this.ListUserType = {};
          await this.processLoadingData();
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }
        }
        //เปิดฟอร์ม
        else if (state == true) {
          this.formInactive = [];
          //กำหนด action insert
          this.action = "Insert";
          // let activeDelete = {
          //   name: "blnDeleteinForm",
          //   inactive: true,
          //   tooltip: false,
          // };
          // this.formInactive.push(activeDelete);
          //เคลียข้อมูลในฟอร์ม
          // console.log(this.formdata.name);
          this.formdata = {};
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },
    // เก็บค่า config
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(
        "SelectedListUserType",
        JSON.stringify(SelectedList)
      );
    },
    // load config
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListUserType"))
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
</style>
